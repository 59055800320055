import React from "react";

import Carousel from "react-material-ui-carousel";

import Cards from "./Cards";

import membersinfo from "../members/members.json";

import "./Profile.css";

const Profile = () => {
  return (
    <>
      <div className="container" style={{ paddingBottom: "3vh" }}>
        <Carousel navButtonsAlwaysVisible={true} autoPlay={false} animation={"fade"}>
          {membersinfo.map((memberinfo, index) => {
            return (
              <Cards
                post={memberinfo.post}
                name={memberinfo.name}
                career={memberinfo.career}
                sns={memberinfo.sns}
                key={index}
              />
            );
          })}
        </Carousel>
      </div>
    </>
  );
};

export default Profile;
