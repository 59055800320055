import React from "react";

import IconButton from "@mui/material/IconButton";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";

const Contact = () => {
  return (
    <>
      <IconButton aria-label="share">
        <LocalPhoneIcon /> 090-5306-0765
      </IconButton>
      <br />
      <IconButton aria-label="share" style={{ textAlign: "left" }}>
        <EmailIcon /> aladdin@aladdin-smahome.com
      </IconButton>
    </>
  );
};

export default Contact;
